<template>
  <div>
    <v-container style="margin-bottom: 10vw; margin-top: 50px">
      <v-row justify="center">
        <v-col cols="12" sm="3" style="display: contents">
          <div
            data-aos-duration="1000"
            data-aos="zoom-out-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <h1
              class="font-weight-bold mb-3 secTitle"
              style="font-size: 60px; width: fit-content"
            >
              {{ title[lang] }}
            </h1>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="4"
          v-for="(blog, index) in blogs"
          :key="index"
          style="min-height: 400pt; text-align: center"
        >
          <v-card
            style="
              width: 100%;
              height: 549px;
              overflow: hidden;
              padding-bottom: 10px;
            "
            data-aos-duration="1000"
            data-aos="fade-up"
            :data-aos-delay="10 * index"
          >
            <img
              :src="origin + blog.photos[0]"
              alt=""
              style="height: 200px; width: 200px"
            />
            <h3>
              {{ blog.title }}
            </h3>
            <div
              style="
                text-align: left;
                padding: 15px;
                padding-bottom: 13px;
                max-height: 53%;
                overflow: hidden;
              "
            >
              <p>
                <img
                  v-for="i in blog.rate"
                  :key="i"
                  src="@/assets/images/star-11.svg"
                  style="height: 30px"
                  data-aos="fade-right"
                />
                <img
                  v-for="i in 5 - blog.rate"
                  :key="i + 5"
                  src="@/assets/images/star 2-01.svg"
                  style="height: 30px"
                  data-aos="fade-right"
                />
                <br />
                <br />
                {{ blog.description }}
              </p>
            </div>
            <div style="width: 100%; height: 20px"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row style="background-color: #005677" justify="center">
      <v-col cols="12" sm="5" style="background-color: #005677"   >
        <b-form @submit.prevent="sendFeedback($event)" >
          <div :style="{ textAlign:lang=='ara'?'right':'left' , width:'100%',  direction:lang=='ara'?'rtl':'ltr'}">
          <h1
            class="font-weight-bold mb-3 secTitle"
            :style="{fontSize: '60px', width: 'fit-content', color: 'white', direction:lang=='ara'?'rtl':'ltr'}"
          >
            {{ feedbackBtn[lang] }}
          </h1>
          </div>
          <b-form-file
            id="image"
            name="photo"
            drop-placeholder="Drop file here..."
            background-color="white"
            style="margin-bottom: 10px"
            :placeholder="choosefile[lang]"
            :browse-text="choosefile[lang]"
            :choose-label="choosefile[lang]"
          
          ></b-form-file>
          <b-form-input
            required
            name="name"
            id="name"
            :placeholder="fname[lang]"
            :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left'}"
            
          >
          </b-form-input>
          <b-form-input
            required
            name="email"
            id="email"
            :placeholder="femail[lang]"
            type="email"
            :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left'}"
          >
          </b-form-input>
          <b-form-input
            name="phone"
            id="phone"
            :placeholder="fphone[lang]"
            type="number"
            :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left'}"
          >
          </b-form-input>
          <b-form-select
            required
            id="rating"
            name="rate"
            :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left', width:'100%'}"
            class="form-control"
            placeholder="Rating"
            v-model="selected"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >{{selectrate[lang]}}</b-form-select-option
              >
            </template>
            <b-form-select-option value="1">1</b-form-select-option>
            <b-form-select-option value="2">2</b-form-select-option>
            <b-form-select-option value="3">3</b-form-select-option>
            <b-form-select-option value="4">4</b-form-select-option>
            <b-form-select-option value="5">5</b-form-select-option>
          </b-form-select>
          <b-form-input
            required
            name="title"
            id="title"
            :placeholder="cfeedtitle[lang]"
            :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left'}"
          >
          </b-form-input>
          <b-form-textarea
            required
            name="description"
            id="description"
            :placeholder="cfeeddescription[lang]"
            :style="{marginBottom: '10px', direction:lang=='ara'?'rtl':'ltr', textAlign:lang=='ara'?'right':'left'}"
          >
          </b-form-textarea>
          <b-button
            block
            style="
              background-color: white;
              margin-top: 10px;
              width: 100%;
              color: #005677;
              margin-bottom: 10px;
            "
            type="submit"
          >
            {{csend[lang]}}
          </b-button>
        </b-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  inject: ["feedbackRepo"],
  data() {
    return {
      selected: null,
      responseMessage: "",
    };
  },
  computed: {
    title() {
      return this.$store.state.feedbackTitle;
    },
    lang() {
      return this.$store.state.lang;
    },
    blogs() {
      return this.$store.state.feedbacks;
    },
    origin() {
      return this.$store.state.origin;
    },
    feedbackBtn() {
      return this.$store.state.feedbackBtn;
    },
    fname() {
      return this.$store.state.fname;
    },
    femail() {
      return this.$store.state.femail;
    },
    fphone() {
      return this.$store.state.fphone;
    },
    choosefile() {
      return this.$store.state.choosefile;
    },
    selectrate() {
      return this.$store.state.selectrate;
    },
    cfeedtitle(){
      return this.$store.state.feedbackTitle;
    },
    cfeeddescription()
    {
      return this.$store.state.cfeeddescription;
    },
    csend()
    {
      return this.$store.state.csend;
    }
  },
  mounted() {
    this.$store.commit("setoverlay", { overlay: false });
  },
  methods: {
    sendFeedback(evt) {
      const messageData = new FormData(evt.target);
      this.feedbackRepo.sendForm(messageData).then((resp) => {
        if (resp["success"]) {
          alert("The feedback sent successfully");
        } else {
          alert(Object.values(resp["data"])[0]);
        }
      });
    },
  },
};
</script>
